@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 30 50% 96.1%;
    --foreground: 233.6 33.3% 16.5%;
    --card: 30 100% 98%;
    --card-foreground: 233.6 33.3% 16.5%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 233.6 33.3% 16.5%;
    --primary-foreground: 30 100% 98%;
    --secondary: 30 50% 96.1%;
    --secondary-foreground: 236.7 9.8% 36.1%;
    --muted: 24 8.2% 88%;
    --muted-foreground: 240 21.9% 20.6%;
    --accent: 228 41% 12%;
    --accent-foreground: 30 100% 98%;
    --destructive: 357.4 100% 68%;
    --destructive-foreground: 0 0% 98%;
    --border: 24 8.2% 88%;
    --input: 30 6.5% 87.8%;
    --ring: 228 41% 12%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground font-body;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
